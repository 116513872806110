.integration {
  padding: 6rem 2rem;
  background: var(--bg-dark);
  position: relative;
}

.integration::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg,
    transparent 0%,
    rgba(74, 222, 128, 0.2) 25%,
    var(--primary-green) 50%,
    rgba(74, 222, 128, 0.2) 75%,
    transparent 100%
  );
}

.integration-container {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.integration-title {
  font-size: 3.5rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  color: var(--text-light);
}

.integration-description {
  font-size: 1.25rem;
  color: var(--text-gray);
  margin-bottom: 3rem;
  max-width: 500px;
}

.steps-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.step-card {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 1.25rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.step-card:hover {
  transform: translateX(5px);
  background: rgba(255, 255, 255, 0.05);
  border-color: var(--primary-green);
}

.step-header {
  margin-bottom: 0.75rem;
}

.step-number {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-green);
  font-size: 0.875rem;
}

.step-check {
  font-size: 0.875rem;
  background: rgba(74, 222, 128, 0.1);
  padding: 0.25rem;
  border-radius: 50%;
}

.step-title {
  color: var(--text-light);
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

.step-description {
  color: var(--text-gray);
  font-size: 0.875rem;
  line-height: 1.5;
}

.integration-visual {
  position: relative;
}

.integration-image-container {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  padding: 1rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.integration-image {
  width: 100%;
  height: auto;
  border-radius: 16px;
  display: block;
  transition: transform 0.3s ease;
}

.integration-image:hover {
  transform: scale(1.02);
}

@media (max-width: 768px) {
  .integration-container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .integration-title {
    font-size: 2.5rem;
  }

  .step-card:hover {
    transform: translateY(-3px);
  }
}