:root {
  --gradient-green: linear-gradient(90deg, #4ade80 0%, #34d399 100%);
  --text-light: #f9fafb;
  --text-gray: #9ca3af;
  --bg-dark: #111827;
  --primary-green: #4ade80;
  --primary-green-rgb: 74, 222, 128;
  --primary-green-dark: #22c55e;
}

.dashboard {
  padding: clamp(1rem, 3vw, 2rem);
  background: var(--bg-dark);
  min-height: 100vh;
  color: var(--text-light);
  width: 100%;
  overflow-x: hidden;
}

.dashboard-header {
  padding-top: calc(var(--header-height) + 1rem);
  margin-bottom: 2rem;
}

.dashboard-header h1 {
  font-size: clamp(1.75rem, 4vw, 2.5rem);
  margin-bottom: 1.5rem;
}

.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 2rem;
}

.filter-btn {
  padding: clamp(0.5rem, 2vw, 0.75rem) clamp(1rem, 3vw, 1.5rem);
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: var(--text-light);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: clamp(0.875rem, 1.5vw, 1rem);
  white-space: nowrap;
}

.filter-btn:hover {
  background: rgba(255, 255, 255, 0.1);
}

.filter-btn.active {
  background: var(--primary-green);
  border-color: var(--primary-green);
}

.facilities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
  gap: clamp(1rem, 3vw, 2rem);
  width: 100%;
}

.facility-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: clamp(1rem, 3vw, 1.5rem);
  cursor: pointer;
  transition: all 0.3s ease;
}

.facility-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.05);
  border-color: var(--primary-green);
}

.facility-card h2 {
  margin-bottom: 1rem;
  font-size: clamp(1rem, 2vw, 1.25rem);
}

.occupancy-bar {
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem;
}

.occupancy-fill {
  height: 100%;
  transition: width 0.3s ease;
}

.facility-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: clamp(0.875rem, 1.5vw, 1rem);
}

.status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
  font-weight: 500;
}

.status-badge.high {
  background: rgba(255, 77, 77, 0.2);
  color: #ff4d4d;
}

.status-badge.moderate {
  background: rgba(255, 215, 0, 0.2);
  color: #ffd700;
}

.status-badge.low {
  background: rgba(76, 175, 80, 0.2);
  color: #4CAF50;
}

.analytics-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.modal-content {
  background: var(--bg-dark);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: clamp(1rem, 3vw, 2rem);
  width: min(95%, 1200px);
  max-height: 90vh;
  overflow-y: auto;
  margin: auto;
}

.modal-content h2 {
  font-size: clamp(1.5rem, 3vw, 2rem);
  margin-bottom: 1.5rem;
}

.occupancy-chart {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: clamp(1rem, 2vw, 1.5rem);
  margin-bottom: 2rem;
  height: clamp(200px, 40vh, 300px);
  transition: all 0.3s ease;
}

.occupancy-chart:hover {
  border-color: var(--primary-green);
  box-shadow: 0 0 20px rgba(var(--primary-green-rgb), 0.1);
}

.occupancy-chart h3 {
  margin-bottom: 1rem;
  color: var(--text-gray);
  font-size: clamp(1rem, 2vw, 1.25rem);
}

.analytics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  gap: clamp(1rem, 2vw, 1.5rem);
  margin: 2rem 0;
}

.analytics-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: clamp(1rem, 2vw, 1.5rem);
  transition: all 0.3s ease;
}

.analytics-card:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: var(--primary-green);
}

.analytics-card h3 {
  color: var(--text-gray);
  margin-bottom: 1rem;
  font-size: clamp(1rem, 2vw, 1.25rem);
}

.analytics-number {
  font-size: clamp(1.75rem, 4vw, 2.5rem);
  font-weight: 600;
  color: var(--primary-green);
  margin: 1rem 0;
}

.analytics-details {
  color: var(--text-gray);
  font-size: clamp(0.875rem, 1.5vw, 0.9rem);
  line-height: 1.6;
}

.recommendation-list p {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.close-modal {
  width: 100%;
  padding: clamp(0.875rem, 2vw, 1rem);
  background: var(--primary-green);
  color: var(--text-light);
  border: none;
  border-radius: 8px;
  font-size: clamp(0.875rem, 1.5vw, 1rem);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 2rem;
}

.close-modal:hover {
  background: var(--primary-green-dark);
  transform: translateY(-2px);
}

.chart-wrapper {
  height: 91%;
  display: flex;
  padding: clamp(0.5rem, 1.5vw, 1rem);
  position: relative;
}

.chart-axis-y {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 1rem;
  color: var(--text-gray);
  font-size: clamp(0.75rem, 1.5vw, 0.8rem);
}

.chart-content {
  flex: 1;
  position: relative;
}

.simple-chart {
  display: flex;
  align-items: flex-end;
  gap: 2px;
  height: 97%;
  position: relative;
  z-index: 2;
}

.chart-grid {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.chart-grid-line {
  height: 20%;
  border-top: 1px dashed rgba(255, 255, 255, 0.1);
}

.chart-bar {
  flex: 1;
  min-width: 2px;
  transition: all 0.3s ease;
  opacity: 0.7;
  border-radius: 2px 2px 0 0;
  position: relative;
  cursor: pointer;
}

.chart-bar:hover,
.chart-bar.active {
  opacity: 1;
  transform: scaleX(1.5);
}

.chart-tooltip {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  background: rgba(0, 0, 0, 0.8);
  padding: 0.5rem;
  border-radius: 4px;
  font-size: clamp(0.75rem, 1.5vw, 0.8rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  white-space: nowrap;
  pointer-events: none;
  z-index: 3;
}

.chart-tooltip::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 8px;
  height: 8px;
  background: rgba(0, 0, 0, 0.8);
}

.chart-tooltip.visible {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) scale(1);
}

.chart-tooltip strong {
  color: var(--primary-green);
  margin-bottom: 0.2rem;
}

.chart-tooltip span {
  color: var(--text-gray);
}

.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: var(--primary-green);
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background: var(--primary-green-dark);
}

@media (max-width: 768px) {
  .dashboard-header {
    padding-top: calc(var(--header-height) + 0.5rem);
  }
  
  .analytics-grid {
    grid-template-columns: 1fr;
  }
  
  .chart-bar {
    min-width: 1px;
  }
}

@media (max-width: 480px) {
  .dashboard {
    padding: 0.75rem;
  }
  
  .filter-buttons {
    gap: 0.5rem;
  }
  
  .modal-content {
    padding: 1rem;
    border-radius: 16px;
  }
  
  .analytics-card {
    padding: 1rem;
  }
}

@supports (padding: max(0px)) {
  .dashboard {
    padding-left: max(1rem, env(safe-area-inset-left));
    padding-right: max(1rem, env(safe-area-inset-right));
  }
  
  .modal-content {
    max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  }
}
