:root {
  --gradient-green: linear-gradient(90deg, #4ade80 0%, #34d399 100%);
  --text-light: #f9fafb;
  --text-gray: #9ca3af;
  --bg-dark: #111827;
  --primary-green: #4ade80;
}

.hero {
  padding: 8rem 2rem 4rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
  min-height: calc(100vh - var(--header-height));
  overflow-x: hidden;
}

.hero-content {
  z-index: 1;
}

.hero-title {
  font-size: 4.5rem;
  line-height: 1.1;
  margin-bottom: 2rem;
  letter-spacing: -0.02em;
  color: var(--text-light);
}

.gradient-text {
  background: var(--gradient-green);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.hero-subtitle {
  font-size: 1.1rem;
  color: var(--text-gray);
  margin-bottom: 3rem;
  line-height: 1.5;
  max-width: 500px;
}

.cta-button {
  background: var(--gradient-green);
  border: none;
  padding: 1.25rem 2.5rem;
  border-radius: 12px;
  color: var(--bg-dark);
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(74, 222, 128, 0.2);
}

.hero-visual {
  position: relative;
  z-index: 1;
  width: 100%;
}

.stats-container {
  width: 100%;
  position: relative;
}

.stats-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  padding: 2.5rem;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  animation: fadeIn 0.3s ease-out;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.facility-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.facility-header h3 {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--text-light);
}

.live-indicator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-green);
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.375rem 0.75rem;
  border-radius: 6px;
  background: rgba(74, 222, 128, 0.1);
  width: fit-content;
}

.pulse {
  width: 8px;
  height: 8px;
  background: var(--primary-green);
  border-radius: 50%;
  animation: pulse 2s infinite;
}

.occupancy-bar {
  height: 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 2rem;
}

.bar-fill {
  height: 100%;
  background: var(--gradient-green);
  border-radius: 6px;
  transition: width 0.5s ease;
}

.occupancy-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.count {
  color: var(--text-light);
  font-size: 1.25rem;
  font-weight: 500;
}

.status {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.status.high {
  background: rgba(255, 59, 48, 0.2);
  color: #ff3b30;
}

.status.moderate {
  background: rgba(255, 149, 0, 0.2);
  color: #ff9500;
}

.status.low {
  background: rgba(74, 222, 128, 0.2);
  color: #4ade80;
}

.card-nav {
  display: flex;
  justify-content: center;
  gap: 0.75rem;
}

.nav-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-dot.active {
  background: var(--primary-green);
  transform: scale(1.2);
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(74, 222, 128, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(74, 222, 128, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(74, 222, 128, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .hero {
    padding: 6rem 1.5rem 3rem;
    gap: 3rem;
  }

  .hero-title {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .hero {
    grid-template-columns: 1fr;
    text-align: center;
    padding: 5rem 1rem 2rem;
  }

  .hero-subtitle {
    margin: 0 auto 2rem;
  }

  .stats-card {
    padding: 1.5rem;
  }

  .facility-header h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 390px) {
  .hero {
    padding: 5rem 0.75rem 2rem;
    overflow-x: hidden;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1rem;
    padding: 0 0.5rem;
  }

  .stats-card {
    padding: 1.25rem;
    margin: 0 0.5rem;
    width: calc(100% - 1rem);
  }

  .facility-header h3 {
    font-size: 1.25rem;
  }

  .count {
    font-size: 1rem;
  }

  .status {
    padding: 0.375rem 0.75rem;
    font-size: 0.75rem;
  }

  .cta-button {
    padding: 1rem 2rem;
    font-size: 1rem;
  }
}
