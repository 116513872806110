.why-us {
  padding: 6rem 2rem;
  background: var(--bg-dark);
  position: relative;
}

.why-us::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg,
    transparent 0%,
    rgba(74, 222, 128, 0.2) 25%,
    var(--primary-green) 50%,
    rgba(74, 222, 128, 0.2) 75%,
    transparent 100%
  );
}

.why-us-container {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
}

.why-us-content {
  max-width: 1000px;
  margin: 0 auto;
}

.why-us-title {
  font-size: 4rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  color: var(--text-light);
}

.why-us-description {
  font-size: 1.25rem;
  color: var(--text-gray);
  margin-bottom: 4rem;
}

.why-us-stats {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 2rem;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 4rem;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
  flex: 1;
}

.stat-number {
  font-size: 3rem;
  font-weight: 700;
  background: var(--gradient-green);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
}

.stat-label {
  color: var(--text-light);
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

.stat-description {
  color: var(--text-gray);
  font-size: 0.875rem;
}

.stat-divider {
  width: 1px;
  background: rgba(255, 255, 255, 0.1);
}

.cta-section {
  text-align: center;
}

.cta-title {
  font-size: 1.5rem;
  color: var(--text-light);
  margin-bottom: 2rem;
}

.cta-button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 2.5rem;
  border-radius: 12px;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  background: var(--gradient-green);
  border: none;
  color: var(--bg-dark);
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(74, 222, 128, 0.2);
}

@media (max-width: 768px) {
  .why-us-title {
    font-size: 2.5rem;
  }

  .why-us-stats {
    flex-direction: column;
    padding: 2rem;
  }

  .stat {
    padding: 1.5rem 1rem;
  }

  .stat-divider {
    width: 80%;
    height: 1px;
    margin: 0 auto;
  }
}