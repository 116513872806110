@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

:root {
  --primary-green: #4ade80;
  --dark-green: #22c55e;
  --bg-dark: #1f2937;
  --text-light: #ffffff;
  --text-gray: #9ca3af;
  --gradient-green: linear-gradient(135deg, #4ade80 0%, #22c55e 100%);
}

body {
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
}