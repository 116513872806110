:root {
  --gradient-green: linear-gradient(90deg, #4ade80 0%, #34d399 100%);
  --text-light: #f9fafb;
  --text-gray: #9ca3af;
  --bg-dark: #111827;
  --primary-green: #4ade80;
}

.about {
  background: var(--bg-dark);
  color: var(--text-light);
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

.about-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.about-hero {
  padding-top: calc(var(--header-height) + 2rem);
  padding-bottom: 4rem;
  width: 100%;
}

.about-hero .about-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}

.about-content {
  width: 100%;
}

.about-title {
  font-size: clamp(2rem, 5vw, 3rem);
  line-height: 1.1;
  margin-bottom: 1.5rem;
  letter-spacing: -0.02em;
}

.gradient-text {
  background: var(--gradient-green);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.about-description {
  font-size: clamp(1rem, 2vw, 1.25rem);
  color: var(--text-gray);
  line-height: 1.6;
}

.about-image-container,
.tech-image-container {
  width: 100%;
}

.about-image,
.tech-image {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  object-fit: cover;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.03);
}

.mission-section {
  padding: 4rem 0;
  background: rgba(255, 255, 255, 0.02);
  width: 100%;
}

.mission-content {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.mission-content h2,
.values-section h2,
.tech-text h2 {
  font-size: clamp(1.75rem, 4vw, 2.5rem);
  margin-bottom: 1.5rem;
  color: var(--primary-green);
}

.mission-content p {
  font-size: clamp(1rem, 2vw, 1.25rem);
  color: var(--text-gray);
  line-height: 1.6;
}

.values-section {
  padding: 4rem 0;
  width: 100%;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  width: 100%;
}

.value-card {
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease;
}

.value-card:hover {
  transform: translateY(-5px);
}

.value-card h3 {
  font-size: clamp(1.25rem, 2vw, 1.5rem);
  margin-bottom: 1rem;
  color: var(--primary-green);
}

.value-card p {
  color: var(--text-gray);
  line-height: 1.5;
  font-size: clamp(0.875rem, 1.5vw, 1rem);
}

.tech-section {
  padding: 4rem 0;
  background: rgba(255, 255, 255, 0.02);
  width: 100%;
}

.tech-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}

.tech-text p {
  font-size: clamp(1rem, 1.5vw, 1.125rem);
  color: var(--text-gray);
  line-height: 1.6;
  margin-bottom: 2rem;
}

.tech-features {
  list-style: none;
  padding: 0;
}

.tech-features li {
  padding: 0.75rem 0;
  color: var(--text-gray);
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: clamp(0.875rem, 1.5vw, 1rem);
}

.tech-features li::before {
  content: '•';
  color: var(--primary-green);
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .about-hero .about-container,
  .tech-content {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .about-content {
    order: 1;
  }

  .about-image-container {
    order: 2;
  }

  .tech-features {
    max-width: 400px;
    margin: 0 auto;
  }

  .tech-features li {
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .about-container {
    padding: 0 1rem;
  }

  .about-hero {
    padding-top: calc(var(--header-height) + 1rem);
  }

  .mission-section,
  .values-section,
  .tech-section {
    padding: 3rem 0;
  }

  .value-card {
    padding: 1.25rem;
  }
}

/* Safe area insets for modern mobile devices */
@supports (padding: max(0px)) {
  .about-container {
    padding-left: max(1rem, env(safe-area-inset-left));
    padding-right: max(1rem, env(safe-area-inset-right));
  }

  .about-hero {
    padding-top: max(calc(var(--header-height) + 2rem), env(safe-area-inset-top));
  }
}
