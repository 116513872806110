:root {
  --gradient-green: linear-gradient(90deg, #4ade80 0%, #34d399 100%);
  --text-light: #f9fafb;
  --text-gray: #9ca3af;
  --bg-dark: #111827;
  --primary-green: #4ade80;
  --primary-green-rgb: 74, 222, 128;
  --primary-green-dark: #22c55e;
}

.contact {
  background: var(--bg-dark);
  color: var(--text-light);
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.contact-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.contact-hero {
  padding-top: calc(var(--header-height) + 2rem);
  padding-bottom: 2rem;
  text-align: center;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(var(--primary-green-rgb), 0.1) 100%);
}

.contact-title {
  font-size: clamp(2.5rem, 5vw, 4.5rem);
  line-height: 1.1;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, var(--text-light) 0%, var(--primary-green) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.contact-description {
  font-size: clamp(1rem, 2vw, 1.25rem);
  color: var(--text-gray);
  max-width: 600px;
  margin: 0 auto;
  padding: 0 1rem;
}

.contact-form-section {
  padding: 3rem 0;
}

.contact-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
}

.info-item {
  text-align: center;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease;
}

.info-item:hover {
  transform: translateY(-5px);
  border-color: var(--primary-green);
}

.info-item h3 {
  color: var(--primary-green);
  margin-bottom: 1rem;
  font-size: clamp(1.25rem, 2vw, 1.5rem);
}

.info-item p {
  color: var(--text-gray);
  font-size: clamp(0.875rem, 1.5vw, 1rem);
}

.contact-form {
  max-width: 800px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.02);
  padding: clamp(1.5rem, 4vw, 3rem);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.form-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-gray);
  font-size: clamp(0.875rem, 1.5vw, 1rem);
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.875rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: var(--text-light);
  font-size: clamp(0.875rem, 1.5vw, 1rem);
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--primary-green);
  background: rgba(var(--primary-green-rgb), 0.1);
}

.submit-btn {
  width: 100%;
  padding: 1rem 1.25rem;
  background: var(--primary-green);
  color: var(--text-light);
  border: none;
  border-radius: 8px;
  font-size: clamp(1rem, 1.5vw, 1.1rem);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.submit-btn:hover {
  background: var(--primary-green-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(var(--primary-green-rgb), 0.3);
}

@media screen and (max-width: 768px) {
  .contact-hero {
    padding-top: calc(var(--header-height) + 1rem);
  }

  .contact-form {
    margin: 0 1rem;
  }
}

@media screen and (max-width: 480px) {
  .contact-container {
    padding: 0 0.75rem;
  }

  .info-item {
    padding: 1.25rem;
  }

  .contact-form {
    padding: 1.25rem;
    margin: 0 0.5rem;
  }

  .form-row {
    gap: 1rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }
}

@supports (padding: max(0px)) {
  .contact-container {
    padding-left: max(1rem, env(safe-area-inset-left));
    padding-right: max(1rem, env(safe-area-inset-right));
  }

  .contact-hero {
    padding-top: max(calc(var(--header-height) + 2rem), env(safe-area-inset-top));
  }
}
