.showcase {
  padding: 6rem 2rem;
  background: linear-gradient(180deg, var(--bg-dark) 0%, rgba(31, 41, 55, 0.8) 100%);
  position: relative;
  overflow: hidden;
}

.showcase::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg,
    transparent 0%,
    rgba(74, 222, 128, 0.2) 25%,
    var(--primary-green) 50%,
    rgba(74, 222, 128, 0.2) 75%,
    transparent 100%
  );
}

/* Rest of the CSS remains the same */
.showcase-container {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.metrics-grid {
  display: grid;
  gap: 1.5rem;
  position: relative;
}

.metric-card {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  transform: translateY(10px);
  opacity: 0.5;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.metric-card.active {
  transform: translateY(0);
  opacity: 1;
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(74, 222, 128, 0.2);
}

.metric-icon {
  font-size: 1.75rem;
  color: var(--primary-green);
  margin-bottom: 1rem;
}

.metric-value {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-light);
  margin-bottom: 0.25rem;
  background: var(--gradient-green);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.metric-label {
  font-size: 1.125rem;
  color: var(--text-light);
  margin-bottom: 0.25rem;
}

.metric-description {
  color: var(--text-gray);
  font-size: 0.875rem;
}

.showcase-content {
  position: relative;
  z-index: 1;
}

.showcase-title {
  font-size: 3.5rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  color: var(--text-light);
}

.showcase-description {
  font-size: 1.25rem;
  color: var(--text-gray);
  margin-bottom: 3rem;
  max-width: 500px;
}

.showcase-cta {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.demo-button {
  background: var(--gradient-green);
  border: none;
  padding: 1rem 2rem;
  border-radius: 12px;
  color: var(--bg-dark);
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.demo-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(74, 222, 128, 0.2);
}

.success-rate {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
}

.success-icon {
  font-size: 1.25rem;
  color: var(--primary-green);
}

.success-text {
  display: flex;
  flex-direction: column;
}

.success-text span {
  color: var(--text-light);
  font-weight: 500;
  font-size: 0.875rem;
}

.success-text small {
  color: var(--text-gray);
  font-size: 0.75rem;
}

@media (max-width: 768px) {
  .showcase-container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .showcase-title {
    font-size: 2.5rem;
  }

  .showcase-cta {
    flex-direction: column;
    align-items: flex-start;
  }
}