:root {
  --header-height: 70px;
  --bg-dark: #111827;
  --text-light: #f9fafb;
  --text-gray: #9ca3af;
  --primary-green: #4ade80;
  --primary-green-rgb: 74, 222, 128;
  --primary-green-dark: #22c55e;
}

.signup-container {
  min-height: 100vh;
  min-height: calc(100vh - var(--header-height));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: clamp(1rem, 5vw, 2rem);
  background: var(--bg-dark);
  background-image: linear-gradient(
    to bottom right,
    rgba(var(--primary-green-rgb), 0.1),
    rgba(0, 0, 0, 0.2)
  );
  margin-top: var(--header-height);
}

.signup-card {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: clamp(16px, 4vw, 24px);
  padding: clamp(1.25rem, 4vw, 2rem);
  width: min(100%, 500px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.signup-card h1 {
  color: var(--text-light);
  font-size: clamp(1.75rem, 5vw, 2.5rem);
  margin-bottom: 0.5rem;
  text-align: center;
}

.signup-subtitle {
  color: var(--text-gray);
  text-align: center;
  margin-bottom: clamp(1.5rem, 4vw, 2rem);
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: clamp(1rem, 3vw, 1.5rem);
}

.signup-form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.signup-form-group label {
  color: var(--text-gray);
  font-size: clamp(0.8125rem, 2vw, 0.9rem);
}

.signup-form-group input,
.signup-form-group select {
  padding: clamp(0.75rem, 2vw, 1rem);
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: var(--text-light);
  font-size: clamp(0.875rem, 2vw, 1rem);
  transition: all 0.3s ease;
  width: 100%;
}

.signup-form-group input:focus,
.signup-form-group select:focus {
  outline: none;
  border-color: var(--primary-green);
  background: rgba(var(--primary-green-rgb), 0.1);
}

.terms-checkbox {
  color: var(--text-gray);
  font-size: clamp(0.8125rem, 2vw, 0.9rem);
  margin: clamp(0.75rem, 2vw, 1rem) 0;
}

.terms-checkbox label {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.terms-checkbox input[type="checkbox"] {
  margin-top: 0.25rem;
  width: 16px;
  height: 16px;
}

.terms-checkbox span {
  line-height: 1.4;
}

.terms-checkbox a {
  color: var(--primary-green);
  text-decoration: none;
}

.terms-checkbox a:hover {
  text-decoration: underline;
}

.signup-button {
  padding: clamp(0.875rem, 2.5vw, 1rem);
  background: var(--primary-green);
  color: var(--text-light);
  border: none;
  border-radius: 8px;
  font-size: clamp(0.875rem, 2vw, 1rem);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.signup-button:hover {
  background: var(--primary-green-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(var(--primary-green-rgb), 0.3);
}

.signup-redirect {
  text-align: center;
  margin-top: clamp(1.5rem, 4vw, 2rem);
  color: var(--text-gray);
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.signup-redirect a {
  color: var(--primary-green);
  text-decoration: none;
  font-weight: 600;
}

.signup-redirect a:hover {
  text-decoration: underline;
}

@supports (padding: max(0px)) {
  .signup-container {
    padding-top: max(var(--header-height), env(safe-area-inset-top));
    padding-bottom: max(1rem, env(safe-area-inset-bottom));
    padding-left: max(1rem, env(safe-area-inset-left));
    padding-right: max(1rem, env(safe-area-inset-right));
  }
}

@media (max-width: 390px) {
  .signup-card {
    padding: 1rem;
  }

  .terms-checkbox label {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .terms-checkbox input[type="checkbox"] {
    margin-top: 0;
  }
}
