.features {
  padding: 8rem 2rem;
  background: rgba(74, 222, 128, 0.03);
}

.features-container {
  max-width: 1400px;
  margin: 0 auto;
}

.features-title {
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
  background: var(--gradient-green);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features-subtitle {
  font-size: 1.25rem;
  text-align: center;
  color: var(--text-gray);
  max-width: 600px;
  margin: 0 auto 4rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.feature-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  padding: 2.5rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 32px rgba(74, 222, 128, 0.1);
}

.feature-icon-wrapper {
  width: 48px;
  height: 48px;
  background: var(--gradient-green);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.feature-icon {
  font-size: 24px;
  color: var(--bg-dark);
}

.feature-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--text-light);
}

.feature-description {
  color: var(--text-gray);
  line-height: 1.6;
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
  }

  .features-title {
    font-size: 2.5rem;
  }
}