:root {
  --header-height: 70px;
  --header-bg: rgba(31, 41, 55, 0.98);
  --text-color: #e5e7eb;
  --menu-bg: rgba(17, 24, 39, 0.98);
  --primary-green: #4ade80;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--header-bg);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 1000;
  height: var(--header-height);
}

.nav-container {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  position: relative;
}

.logo-container {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.logo-image {
  padding-top: 20px;
  height: 200px;
  width: auto;
  object-fit: contain;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.logo-link:hover .logo-image {
  transform: scale(1.05);
}

.desktop-nav {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.nav-icon {
  font-size: 1.2rem;
}

.nav-link:hover {
  color: #fff;
  transform: translateY(-2px);
}

.dashboard-link {
  color: var(--primary-green);
}

.dashboard-link:hover {
  color: #4ade80;
}

.signin-link {
  background: var(--primary-green);
  padding: 0.5rem 1.25rem;
  border-radius: 8px;
  color: var(--header-bg);
}

.signin-link:hover {
  background: #22c55e;
  color: var(--header-bg);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(74, 222, 128, 0.2);
}

.menu-btn {
  display: none;
}

.options-menu {
  display: none;
}

@media (max-width: 768px) {
  .nav-container {
    padding: 0 1rem;
  }

  .desktop-nav {
    display: none;
  }

  .menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 24px;
    padding: 8px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    z-index: 1001;
  }

  .logo-image {
    height: 120px;
  }

  .options-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: var(--header-height);
    left: 0;
    width: 100%;
    height: calc(100vh - var(--header-height));
    background: var(--menu-bg);
    transform: translateX(100%);
    transition: transform 0.3s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
  }

  .options-menu.active {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  .menu-content {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }

  .menu-signin {
    padding: 1rem 1.5rem;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
  }

  .menu-item {
    color: var(--text-color);
    text-decoration: none;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.3s ease;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
  }

  .menu-item:active {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .signin-item {
    background: var(--primary-green);
    border-radius: 8px;
    color: var(--header-bg);
    margin: 0;
  }

  .signin-item:active {
    background: #22c55e;
  }
}

@media (max-width: 390px) {
  .nav-container {
    padding: 0 0.75rem;
  }

  .logo-image {
    height: 135px;
    padding-top: 15px;
  }

  .menu-btn {
    padding: 8px;
    font-size: 22px;
  }

  .menu-item {
    font-size: 0.9rem;
  }
}

@supports (padding: max(0px)) {
  .header {
    padding-top: max(0px, env(safe-area-inset-top));
  }

  .options-menu {
    height: calc(100vh - var(--header-height) - env(safe-area-inset-top));
    padding-bottom: env(safe-area-inset-bottom);
  }
}
