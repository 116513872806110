:root {
  --header-height: 70px;
  --bg-dark: #111827;
  --text-light: #f9fafb;
  --text-gray: #9ca3af;
  --primary-green: #4ade80;
  --primary-green-rgb: 74, 222, 128;
  --primary-green-dark: #22c55e;
}

.signin-container {
  min-height: 100vh;
  min-height: calc(100vh - var(--header-height));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: clamp(1rem, 5vw, 2rem);
  background: var(--bg-dark);
  background-image: linear-gradient(
    to bottom right,
    rgba(var(--primary-green-rgb), 0.1),
    rgba(0, 0, 0, 0.2)
  );
  margin-top: var(--header-height);
}

.signin-card {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: clamp(16px, 4vw, 24px);
  padding: clamp(1.5rem, 5vw, 3rem);
  width: min(100%, 500px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.signin-card h1 {
  color: var(--text-light);
  font-size: clamp(1.75rem, 5vw, 2.5rem);
  margin-bottom: 0.5rem;
  text-align: center;
}

.signin-subtitle {
  color: var(--text-gray);
  text-align: center;
  margin-bottom: clamp(1.5rem, 4vw, 2rem);
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.signin-form {
  display: flex;
  flex-direction: column;
  gap: clamp(1rem, 3vw, 1.5rem);
}

.signin-form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.signin-form-group label {
  color: var(--text-gray);
  font-size: clamp(0.8125rem, 2vw, 0.9rem);
}

.signin-form-group input {
  padding: clamp(0.75rem, 2vw, 1rem);
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: var(--text-light);
  font-size: clamp(0.875rem, 2vw, 1rem);
  transition: all 0.3s ease;
  width: 100%;
}

.signin-form-group input:focus {
  outline: none;
  border-color: var(--primary-green);
  background: rgba(var(--primary-green-rgb), 0.1);
}

.signin-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.remember-me {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-gray);
  font-size: clamp(0.8125rem, 2vw, 0.9rem);
}

.forgot-password {
  color: var(--primary-green);
  text-decoration: none;
  font-size: clamp(0.8125rem, 2vw, 0.9rem);
}

.forgot-password:hover {
  text-decoration: underline;
}

.signin-button {
  padding: clamp(0.875rem, 2.5vw, 1rem);
  background: var(--primary-green);
  color: var(--text-light);
  border: none;
  border-radius: 8px;
  font-size: clamp(0.875rem, 2vw, 1rem);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.google-signin-button {
  margin-top: 10px;
  padding: clamp(0.875rem, 2.5vw, 1rem);
  background: var(--primary-green);
  color: var(--text-light);
  border: none;
  border-radius: 8px;
  font-size: clamp(0.875rem, 2vw, 1rem);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.signin-button:hover {
  background: var(--primary-green-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(var(--primary-green-rgb), 0.3);
}

.signin-redirect {
  text-align: center;
  margin-top: clamp(1.5rem, 4vw, 2rem);
  color: var(--text-gray);
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.signin-redirect a {
  color: var(--primary-green);
  text-decoration: none;
  font-weight: 600;
}

.signin-redirect a:hover {
  text-decoration: underline;
}

@supports (padding: max(0px)) {
  .signin-container {
    padding-top: max(var(--header-height), env(safe-area-inset-top));
    padding-bottom: max(1rem, env(safe-area-inset-bottom));
    padding-left: max(1rem, env(safe-area-inset-left));
    padding-right: max(1rem, env(safe-area-inset-right));
  }
}

@media (max-width: 390px) {
  .signin-card {
    padding: 1.25rem;
  }

  .signin-options {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }
}
