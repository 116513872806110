.footer {
  background: var(--bg-dark);
  padding: 4rem 2rem 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-container {
  max-width: 1400px;
  margin: 0 auto;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
  margin-bottom: 4rem;
}

.footer-section h3 {
  color: var(--text-light);
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin-bottom: 0.75rem;
}

.footer-section ul li a {
  color: var(--text-gray);
  text-decoration: none;
  font-size: 0.875rem;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: var(--primary-green);
}

.footer-bottom {
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo .logo-text {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-light);
}

.footer-info {
  text-align: right;
}

.footer-info p {
  color: var(--text-gray);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.footer-links {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer-links a {
  color: var(--text-gray);
  text-decoration: none;
  font-size: 0.875rem;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: var(--primary-green);
}

.footer-links .divider {
  color: var(--text-gray);
  font-size: 0.875rem;
}

@media (max-width: 1024px) {
  .footer-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .footer-bottom {
    flex-direction: column;
    text-align: center;
    gap: 1.5rem;
  }

  .footer-info {
    text-align: center;
  }

  .footer-links {
    justify-content: center;
    flex-wrap: wrap;
  }
}